<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="ruKuDanID" label="入库单ID" />
            <x-detail-item prop="ruKuShangPinZongShu" label="入库商品总数" />
            <x-detail-item prop="ruKuJinE" label="入库金额" />
            <x-detail-item prop="ruKuLX" label="入库类型" >
                <x-dict-show :code="entity.ruKuLX" dictType="T_RU_KU_TYPE"/>
            </x-detail-item>
            <x-detail-item prop="ruKuCangKu" label="入库仓库" />
<!--            <x-detail-item prop="shiShouShangPinZongShu" label="实收商品总数" />
            <x-detail-item prop="shiShouJinE" label="实收金额" />-->
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="caoZuoRenID" label="操作人ID" />
            <x-detail-item prop="ruKuSJ" label="入库时间" />
            <x-detail-item prop="beiZhu" label="备注" />
        </x-detail>
        <x-divider title="入库信息明细"></x-divider>
        <el-table :data="entity.ruKuXinXiMXs" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="ruKuDanID" label="入库单ID" />
            <el-table-column width="150" prop="shangPinMC" label="商品名称" />
            <el-table-column width="150" prop="shangPinID" label="商品ID" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="shengChanRQ" label="生产日期" />
            <el-table-column width="150" prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column width="150" prop="fuZhuSL" label="应收数量" />
            <el-table-column width="150" prop="chengBenJia" label="成本价" />
            <el-table-column width="150" prop="chengBenJinEXiaoJi" label="成本金额小计" />
            <el-table-column width="150" prop="ruKuSL" label="入库数量" />
            <el-table-column width="150" prop="ruKuJia" label="入库价" />
            <el-table-column width="150" prop="ruKuJinEXiaoJi" label="入库金额小计" />
            <el-table-column width="150" prop="jvShouSL" label="拒收数量" />
            <el-table-column width="150" prop="jvShouJinEXiaoJi" label="拒收金额小计" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hlgl/RuKuXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                ruKuDanID: "", // 入库单ID
                ruKuShangPinZongShu: "", // 入库商品总数
                ruKuJinE: "", // 入库金额
                ruKuLX: "", // 入库类型
                ruKuCangKu: "", // 入库仓库
                shiShouShangPinZongShu: "", // 实收商品总数
                shiShouJinE: "", // 实收金额
                caoZuoRen: "", // 操作人
                caoZuoRenID: "", // 操作人ID
                ruKuSJ: "", // 入库时间
                beiZhu: "", // 备注
                ruKuXinXiMXs: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>