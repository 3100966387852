<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="80%">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <tr class="highlighted">
                <td colspan="3">(请先选 入库类型 和 供货商 后再搜索商品！)</td>
            </tr>
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人" v-show="false" prop="caoZuoRen">
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="入库类型" prop="ruKuLX">
                <el-select v-model="form.ruKuLX" placeholder="请选择入库类型" clearable>
                    <el-option :label="rklx.CGRK.name" :value="rklx.CGRK.key"></el-option>
                    <el-option :label="rklx.QTRK.name" :value="rklx.QTRK.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="供货商" prop="gongHuoShang" v-if ="showGongHuoShang" >
                <el-select v-model="form.gongHuoShang" size="small" maxlength="36" show-word-limit  clearable>
                    <el-option v-for="item in gongHuoShangOptions" :key="item.gongHuoShang" :label="item.gongHuoShang"
                               :value="item.gongHuoShangMC"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="入库时间" prop="ruKuSJ" v-if="showInputTime">
                <el-date-picker v-model="form.ruKuSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu">
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <x-divider title="入库信息明细"></x-divider>


            <x-selector
                    placement="center"
                    selector="ShangPinXXAllGuiGeSelector"
                    @selected="handleClSelect"
                    :param="{'gongHuoShangMC': null}"
                    v-if="form.ruKuLX == rklx.QTRK.key"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round >添加商品</el-button>
            </x-selector>
            <x-selector
                    placement="center"
                    selector="CaiGouDanXiangQingSelector"
                    @selected="handleClSelectCG"
                    :param="{'gongHuoShang': form.gongHuoShang}"
                    v-if="form.ruKuLX == rklx.CGRK.key"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round >添加商品</el-button>
            </x-selector>

            <x-row :rows="form.ruKuXinXiMXs" :rowModel="ruKuXinXiMXsModel" no-add-btn >
                <x-row-cell title="商品名称"  read="shangPinMC">
                </x-row-cell>
                <x-row-cell  title="未入库数量"  read="weiRuKuSL">
                </x-row-cell>
                <x-row-cell title="入库数量"  prop="ruKuSL">
                    <el-input slot-scope="{row}" v-model="row.ruKuSL" size="small" maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="参考进货价"  prop="ruKuJia">
                    <el-input slot-scope="{row}" v-model="row.ruKuJia" size="small" maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="生产日期" width="150" prop="shengChanRQ">
                    <el-input slot-scope="{row}" v-model="row.shengChanRQ" size="small" maxlength="8"  type="date" value-format="yyyy-MM-dd"/>
                </x-row-cell>
                <x-row-cell title="保质期（天）"  prop="baoZhiQiTian">
                    <el-input slot-scope="{row}" v-model="row.baoZhiQiTian" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="规格"  read="guiGe">
                </x-row-cell>
                <x-row-cell title="货号"  read="huoHao">
                </x-row-cell>
                <x-row-cell title="条码"  read="tiaoMa">
                </x-row-cell>
                <x-row-cell title="单位"  read="danWei">
                </x-row-cell>

                <x-row-cell title="备注"  prop="beiZhu">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="18"/>
                </x-row-cell>
            </x-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>

    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hlgl/RuKuXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import {TYPE_RU_KU_L_X} from "@/util/constant";
    import ShangPinXXAllGuiGeSelector from "@/view/selector/ShangPinXXAllGuiGeSelector";
    import CaiGouDanXiangQingSelector from "@/view/selector/CaiGouDanXiangQingSelector";
    import {getGongHuoShangList} from "@/service/cggl/CaiGouDan";
    export default {
        mixins: [XEditBase],
        components: {ShangPinXXAllGuiGeSelector,CaiGouDanXiangQingSelector},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.titlePrefix = '入库信息';
            this.ruKuXinXiMXsModel = {
                caiGouSL: "", // 采购数量
                keRuKuSL: "", // 可入库数量
                yiRuKuSL: "", // 已入库数量
                yiTuiHuoSL: "", // 已退货数量
                weiRuKuSL: "", // 未入库数量
                ruKuDanID: "", // 入库单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                shangPinHuoHao: "",//商品货号
                gongHuoShang: "",
                gongHuoShangID: "",
                guiGe: "", // 规格
                tiaoMa: "", // 条码
                huoHao: "", // 货号
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                ruKuSL: "", // 入库数量
                ruKuJia: "", // 入库价
                ruKuJinEXiaoJi: "", // 入库金额小计
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                ruKuDanID: "", // 入库单ID
                ruKuShangPinZongShu: "", // 入库商品总数
                ruKuJinE: "", // 入库金额
                ruKuLX: "", // 入库类型
                gongHuoShang: "",
                gongHuoShangID: "",
                ruKuCangKu: "", // 入库仓库
                shiShouShangPinZongShu: "", // 实收商品总数
                shiShouJinE: "", // 实收金额
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                ruKuSJ: "", // 入库时间
                beiZhu: "", // 备注
                ruKuXinXiMXs: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        computed: {
            showInputTime() {
                // 根据选择的入库类型判断是否需要显示入库时间表单项
                return this.form.ruKuLX === '补货入库' || this.form.ruKuLX === '调拨入库' || this.form.ruKuLX === '其它入库';
            },
        },
        data() {
            let {required,digits,positiveEightTwo} = ruleBuilder;

            return {
                rules:{
                    shengChanRQ: [required()],
                    ruKuSL: [required(),positiveEightTwo()],
                    ruKuJia: [required(),positiveEightTwo()],
                    baoZhiQiTian: [required(),digits()],
                    ruKuLX: [required()], // 入库类型
                    ruKuDanID: [], // 入库单ID
                    ruKuShangPinZongShu: [], // 入库商品总数
                    ruKuJinE: [], // 入库金额
                    ruKuCangKu: [], // 入库仓库
                    gongHuoShang: [required()], // 供货商
                    shiShouShangPinZongShu: [], // 实收商品总数
                    shiShouJinE: [], // 实收金额
                    caoZuoRen: [required()], // 操作人
                    caoZuoRenID: [], // 操作人ID
                    ruKuSJ: [required()], // 入库时间
                    beiZhu: [], // 备注
                },
                showGongHuoShang: false,
                options: [],
                rklx: TYPE_RU_KU_L_X,
                cGDXQOptions: [],
                gongHuoShangOptions: [],
                chooseTable: [],
                isShowAddList: false
            }
        },
        watch: {
            'form.ruKuLX': function (val) {
                if (val === this.rklx.QTRK.key) {
                    // 如果选择的出库类型是DBCK，则不显示货至输入框
                    this.showGongHuoShang = false;
                } else {
                    // 其他类型则显示货至输入框
                    this.showGongHuoShang = true;
                }
            }
        },
        mounted() {
            this.getGHSList()
          /*  this.getCKList()*/
        },
        methods: {
            handleClSelect(cls) {
                let cc = [];
                this.form.ruKuXinXiMXs.forEach(item=>cc.push(item.id));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.id)){
                        obj.shangPinMC = cl.shangPinMC || '';
                        obj.shangPinID = cl.id || '';
                        obj.weiRuKuSL = cl.weiRuKuSL || '';
                        obj.guiGe = cl.ciJiGuiGeMC || '';
                        obj.danWei = cl.shangPinDW || '';
                        obj.gongHuoShang = cl.gongHuoShangMC || '';
                        obj.gongHuoShangID = cl.gongHuoShang || '';
                        if(cl.shiFuDuoGuiGe === 'true'){
                            obj.jinHuoJia = cl.guiGeShangPinJinJia || '';
                            obj.huoHao = cl.guiGeHuoHao || '';
                            obj.tiaoMa = cl.guiGeTiaoMa || '';
                        }else {
                            obj.jinHuoJia = cl.shangPinJinJia || '';
                            obj.huoHao = cl.shangPinHuoHao || '';
                            obj.tiaoMa = cl.shangPinTiaoMa || '';
                        }
                        obj.ruKuJia = obj.jinHuoJia || '';
                        this.form.ruKuXinXiMXs.push(obj);
                    }
                });
            },
            handleClSelectCG(cls) {
                let cc = [];
                this.form.ruKuXinXiMXs.forEach(item=>cc.push(item.shangPinID));
                cls.forEach(cl => {
                    let obj = {};
                    if(!cc.includes(cl.shangPinID)){
                        obj.caiGouDanXiangQingID = cl.id || '';
                        obj.shangPinID = cl.shangPinID || '';
                        obj.shangPinMC = cl.shangPinMC || '';
                        obj.yiRuKuSL = cl.yiRuKuSL || '';
                        obj.weiRuKuSL = cl.weiRuKuSL || '';
                        obj.guiGe = cl.guiGe || '';
                        obj.huoHao = cl.huoHao || '';
                        obj.tiaoMa = cl.tiaoMa || '';
                        obj.danWei = cl.danWei || '';
                        obj.shengChanRQ = cl.shengChanRQ || '';
                        obj.baoZhiQiTian = cl.baoZhiQiTian || '';
                        obj.caiGouSL = cl.caiGouSL || '';
                        obj.gongHuoShang = cl.gongHuoShang || '';
                        obj.gongHuoShangID = cl.gongHuoShangID || '';
                        obj.jinHuoJia = cl.jinHuoJia || '';
                        obj.ruKuJia = cl.jinHuoJia || '';
                        this.form.ruKuXinXiMXs.push(obj);
                    }

                });
            },

            getGHSList() {
                getGongHuoShangList().then((response) => {
                    this.gongHuoShangOptions = response.data
                })
            },
        }
    }
</script>

<style scoped>
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #f2f2f2; /* 设置表头背景颜色 */
    }

    .highlighted {
        background-color: yellow; /* 设置醒目颜色 */
    }
</style>
