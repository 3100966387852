<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="入库单ID">
                <el-input v-model="search.ruKuDanID" size="small"/>
            </x-search-item>
            <x-search-item label="入库时间" textWidth="200px" hasEnd>
                <el-date-picker v-model="search.ruKuSJBegin" size="small" type="datetime" :style="{ width: '100%' }"/>
                <el-date-picker v-model="search.ruKuSJEnd" size="small" type="datetime" slot="end"
                                :style="{ width: '100%' }"/>
            </x-search-item>

            <x-search-item label="入库类型">
                <el-select v-model="search.ruKuLX" placeholder="请选择入库类型" size="small">
                    <el-option :label="rklx.SYLX.name" :value="rklx.SYLX.key"></el-option>
                    <el-option :label="rklx.CGRK.name" :value="rklx.CGRK.key"></el-option>
                    <el-option :label="rklx.BHRK.name" :value="rklx.BHRK.key"></el-option>
                    <el-option :label="rklx.PYRK.name" :value="rklx.PYRK.key"></el-option>
                    <el-option v-if="!showOption" :label="rklx.MDTHRK.name" :value="rklx.MDTHRK.key"></el-option>

                    <el-option v-if="showOption" :label="rklx.SHDBRK.name" :value="rklx.SHDBRK.key"></el-option>
                    <el-option v-if="showOption" :label="rklx.MDDBRK.name" :value="rklx.MDDBRK.key"></el-option>
                    <el-option v-if="showOption" :label="rklx.SQDBRK.name" :value="rklx.SQDBRK.key"></el-option>
                    <el-option :label="rklx.QTRK.name" :value="rklx.QTRK.key"></el-option>
                </el-select>
            </x-search-item>
            <x-search-item label="供货商" >
                <el-select v-model="search.gongHuoShang" size="small" maxlength="36" show-word-limit>
                    <el-option v-for="item in gongHuoShangOptions" :key="item.gongHuoShang" :label="item.gongHuoShang"
                               :value="item.gongHuoShangMC"></el-option>
                </el-select>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <!--            <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column prop="caoZuoRen" label="操作人" show-overflow-tooltip/>
            <el-table-column  prop="chuKuSJ" label="创建时间" show-overflow-tooltip/>
            <el-table-column  prop="ruKuSJ" label="入库时间" show-overflow-tooltip/>
            <el-table-column prop="ruKuDanID" label="入库单ID" show-overflow-tooltip/>
            <el-table-column prop="ruKuLX" label="入库类型" show-overflow-tooltip>
                <x-dict-show slot-scope="{row}" :code="row.ruKuLX" dictType="T_RU_KU_TYPE"/>
            </el-table-column>
            <el-table-column prop="gongHuoShang" label="供货商" show-overflow-tooltip/>
            <el-table-column prop="ruKuCangKu" label="入库仓库" show-overflow-tooltip/>
            <el-table-column prop="yingShouShangPinZongShu" label="应收商品总数" show-overflow-tooltip/>
            <el-table-column prop="yingShouJinE" label="应收金额" show-overflow-tooltip/>
            <el-table-column prop="shiShouShangPinZongShu" label="实收商品总数" show-overflow-tooltip/>
            <el-table-column prop="shiShouJinE" label="实收金额" show-overflow-tooltip/>
            <el-table-column prop="zhuangTai" label="状态">
                <x-dict-show slot-scope="{row}" :code="row.zhuangTai" dictType="T_RU_KU_STATUS"/>
            </el-table-column>
            <el-table-column prop="beiZhu" label="备注" show-overflow-tooltip/>
            <el-table-column width="180" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button v-if="row.zhuangTai === rkZT.WRK.key " type="primary" size="mini" round @click="handleUpdate(row.id)">入库</el-button>
                    <el-popconfirm title="拒收后不可恢复，确认拒收吗？" @confirm="handleDelete(row.id)">
                        <el-button v-if="row.zhuangTai === rkZT.WRK.key && row.ruKuLX !==rklx.MDTHRK.key" type="danger" size="mini" round slot="reference">拒收</el-button>
                    </el-popconfirm>
                    <!--                    <el-button v-show="row.zhuangTai ===hlZT.YCK.key " type="primary" size="mini" round @click="handleUpdate(row.id)">入库</el-button>
                                        <el-popconfirm v-show="row.zhuangTai ===hlZT.YCK.key " title="拒收后不可恢复，确认拒收吗？" @confirm="handleDelete(row.id)">
                                            <el-button type="danger" size="mini" round slot="reference">拒收</el-button>
                                        </el-popconfirm>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <oldEdit ref="oldEdit" :editor="oldEditor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hlgl/RuKuXX";
    import XTableBase from "@/components/x/XTableBase";
    import OldEdit from "@/view/hlgl/rkd/RuKuXXEdit";
    import Edit from "@/view/hlgl/rkd/RuKuDiaoBoEdit";
    import Detail from "@/view/hlgl/rkd/RuKuXXDetail";
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import {TYPE_RU_KU_STATUS} from "@/util/constant"
    import {TYPE_RU_KU_L_X,DICT_TYPE_USER_ORGAN_LX} from "@/util/constant";
    import {getGongHuoShangList} from "@/service/cggl/CaiGouDan";

    export default {
        name: "RuKuXXList",
        mixins: [XTableBase],
        components: {Edit, OldEdit, Detail,},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                rklx: TYPE_RU_KU_L_X,
                rkZT: TYPE_RU_KU_STATUS,
                options: [],
                gongHuoShangOptions: [],
                search: {
                    ruKuDanID: '',
                    ruKuCangKu: '',
                    ruKuLX: '',
                    ownerDeptCode: '',
                    ownerDeptName: '',
                },
                oldEditor: {
                    visible: false,
                    id: null,
                    isUpdate: false
                },
            }
        },
        mounted() {
            this.getCKList()
            this.getGHSList()
        },
        methods: {
            // 获取仓库下拉选择列表
            getCKList() {
                getCangKuList().then((response) => {
                    this.options = response.data
                })
            },
            getGHSList() {
                getGongHuoShangList().then((response) => {
                    this.gongHuoShangOptions = response.data
                })
            },
            handleDelete(id) {
                this.service.jvShou(id).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                });
            },
            handleAdd() {
                this.oldEditor.isUpdate = false;
                this.oldEditor.id = null;
                this.oldEditor.visible = true;
            },
            handleUpdate(id) {
                this.editor.isUpdate = true;
                this.editor.id = id;
                this.editor.visible = true;
            },
        },
        computed: {
            showOption() {
                // 门店是true
                return this.$store.getters.user.userOrganLx===DICT_TYPE_USER_ORGAN_LX.MD.key;
            }
        }
    }
</script>

<style scoped>
</style>
