<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人" v-show="false" prop="caoZuoRen">
                <el-input v-model="form.caoZuoRen" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="入库仓库" prop="ruKuCangKu" >
                <el-select v-model="form.ruKuCangKu" size="small" disabled>
                    <el-option v-for="item in options" :key="item.ruKuCangKu" :label="item.ruKuCangKu"
                               :value="item.cangKuMC"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item width="150"  prop="ruKuLX" label="入库类型" show-overflow-tooltip>
                <x-selector-one v-model="form.ruKuLX" disabled dictType="T_RU_KU_L_X"/>
            </el-form-item>
            <el-form-item label="入库时间" prop="ruKuSJ" v-if="showInputTime">
                <el-date-picker v-model="form.ruKuSJ" size="small" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"/>
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu">
                <el-input v-model="form.beiZhu" size="small" maxlength="64" show-word-limit/>
            </el-form-item>
            <x-divider title="入库信息明细"></x-divider>
        </el-form>

        <el-table
                ref="multipleTable"
                :data="form.ruKuXinXiMXs"
                tooltip-effect="dark"
                style="width: 100%">
            <el-table-column width="150" prop="shangPinMC" label="商品名称" show-overflow-tooltip></el-table-column>
            <el-table-column width="70" prop="fuZhuSL" label="出库数量" show-overflow-tooltip></el-table-column>
            <el-table-column width="70" prop="chengBenJia" label="出库参考价" show-overflow-tooltip></el-table-column>
            <el-table-column label="入库数量" width="150">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.ruKuSL" size="small"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column width="150" prop="ruKuJia" label="入库价" >
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.ruKuJia" size="small"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column width="120" prop="shengChanRQ" label="生产日期" >
                <el-date-picker slot-scope="{row}" v-model="row.shengChanRQ" size="small" type="date" value-format="yyyy-MM-dd"/>
            </el-table-column>
            <el-table-column width="150" prop="baoZhiQiTian" label="保质期（天）" >
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.baoZhiQiTian" size="small"></el-input-number>
                </template>
            </el-table-column>
            <!--这里是选中后展示的列表-->
            <el-table-column width="100" prop="guiGe" label="规格" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="huoHao" label="货号" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="tiaoMa" label="条码" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="danWei" label="单位" show-overflow-tooltip></el-table-column>
            <el-table-column width="150" prop="gongHuoShang" label="供货商" show-overflow-tooltip></el-table-column>
           <!-- <el-table-column width="150" prop="jinHuoJiaHeJi" label="进货价合计" show-overflow-tooltip></el-table-column>-->
            <el-table-column width="150" prop="beiZhu" label="备注" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-input v-model="scope.row.beiZhu" size="small"></el-input>
                </template>
            </el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>

    </el-dialog>
</template>

<script>
    import {add, diaoBoRuKuUpdate, getUpdate} from "@/service/hlgl/RuKuXX";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {getCangKuList} from "@/service/cggl/TuiHuoDan";
    import {TYPE_RU_KU_L_X} from "@/util/constant";
    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                ruKuDanID: [], // 入库单ID
                ruKuShangPinZongShu: [], // 入库商品总数
                ruKuJinE: [], // 入库金额
                ruKuLX: [], // 入库类型
                ruKuCangKu: [], // 入库仓库
                shiShouShangPinZongShu: [], // 实收商品总数
                shiShouJinE: [], // 实收金额
                caoZuoRen: [required()], // 操作人
                caoZuoRenID: [], // 操作人ID
                ruKuSJ: [required()], // 入库时间
                beiZhu: [], // 备注
            }
            this.titlePrefix = '入库信息';
            this.ruKuXinXiMXsModel = {
                ruKuDanID: "", // 入库单ID
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                shangPinHuoHao: "",//商品货号
                gongHuoShang: "",
                gongHuoShangID: "",
                guiGe: "", // 规格
                tiaoMa: "", // 条码
                huoHao: "", // 货号
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                ruKuSL: "", // 入库数量
                ruKuJia: "", // 入库价
                ruKuJinEXiaoJi: "", // 入库金额小计
                beiZhu: "", // 备注
            }
            this.defaultForm = {
                id: null,
                ruKuDanID: "", // 入库单ID
                ruKuShangPinZongShu: "", // 入库商品总数
                ruKuJinE: "", // 入库金额
                ruKuLX: "", // 入库类型
                gongHuoShang: "",
                gongHuoShangID: "",
                ruKuCangKu: "", // 入库仓库
                shiShouShangPinZongShu: "", // 实收商品总数
                shiShouJinE: "", // 实收金额
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                ruKuSJ: "", // 入库时间
                beiZhu: "", // 备注
                ruKuXinXiMXs: [],
            };
            this.addService = add;
            this.updateService = diaoBoRuKuUpdate;
            this.getUpdateService = getUpdate;
        },
        computed: {
            showInputTime() {
                // 根据选择的入库类型判断是否需要显示入库时间表单项
                return this.form.ruKuLX === '补货入库' || this.form.ruKuLX === '调拨入库' || this.form.ruKuLX === '其它入库';
            }
        },
        data() {
            return {
                rkFS:TYPE_RU_KU_L_X,
                options: [],
                cGDXQOptions: [],
                gongHuoShangOptions: [],
                chooseTable: [],
                isShowAddList: false
            }
        },
        methods: {



        }
    }
</script>

<style scoped>

</style>
